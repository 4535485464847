:root{
  --common-color: blue;
}
.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* Set a negative z-index to make sure it's behind other elements */
  background-image: url('images/main-bg.jpg');
  /* Replace with your image path */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* opacity: 0.5; */
  /* Adjust the opacity as needed */
}

.vertical-space-22{
  margin-top: 220px;
}

.vertical-space-7{
  margin-top: 70px;
}

.top-bar{
  background-color: white;
  min-height: 100px;
}

.logo{
  max-width: 200px;
  padding: 10px;
}

.start-quote{
  border: none;
  background-color: var(--common-color);
  color: white;
  padding: 20px;
  font-size: x-large;
  /* border-radius: 10px; */
  transition: 0.3s all ease-in-out;
  width: 100%;
}

@media (max-width: 800px) {
  .start-quote {
    font-size: small;
  }
}

.start-quote-no-bg{
  background-color: white;
  border: 3px solid var(--common-color);
  color: var(--common-color);
}

.start-quote:hover{
  background-color: rgb(158, 158, 255);
}

.start-quote-no-bg:hover{
  color: white;
}

.start-quote:focus{
  border: none;
  outline: none;
}

.main-card{
  background-color: white;
  width: 100%;
  min-height: 350px;
  height: auto;
  padding: 20px;
  padding-right: 40px;
  padding-top: 30px;
  margin-right: 40px;
  box-shadow:
    -30px 30px 0px /* blur effect */ -5px cadetblue, /* Left shadow */
    0 0px 0px -5px cadetblue; /* Bottom shadow */
}

.main-card-p{
  font-size: x-large;
}

.main-card-h1{
  color: var(--common-color);
}

.four-boxes {
  width: 100%;
  min-height: 200px;
  background-color: white;
}

.one-box{
    width: 100%;
    min-height: 350px;
    height: auto;
    padding: 20px;
    padding-left: 40px;
    padding-top: 30px;
    margin-left: 40px;
}

@media (max-width: 800px) {
  .one-box {
    width: 90%;
  }
}

.banner-column:hover{
  opacity: 0.8;
}

progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

progress[value]::-webkit-progress-value {
  background-image:
    -webkit-linear-gradient(-45deg,
      transparent 33%, rgba(0, 0, 0, .1) 33%,
      rgba(0, 0, 0, .1) 66%, transparent 66%),
    -webkit-linear-gradient(top,
      rgba(255, 255, 255, .25),
      rgba(0, 0, 0, .25)),
    -webkit-linear-gradient(left, #09c, #f44);

  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Get rid of default border in Firefox. */
  border: none;

  /* Dimensions */
  width: 250px;
  height: 20px;
}

progress[value]::-moz-progress-bar {
  background-image:
    -moz-linear-gradient(135deg,
      transparent 33%,
      rgba(0, 0, 0, 0.1) 33%,
      rgba(0, 0, 0, 0.1) 66%,
      transparent 66%),
    -moz-linear-gradient(top,
      rgba(255, 255, 255, 0.25),
      rgba(0, 0, 0, 0.25)),
    -moz-linear-gradient(left,
      #09c,
      #f44);

  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

body{
  background-color: #e83939;
}

.main-question-row{
  opacity: 0;
  animation: zoomIn 0.5s forwards;
}

@keyframes zoomIn {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.questionButton{
  background-color: #a12525;
  height: 40px;
  width: 180px;
  color: white;
  border: none;
}

.questionButton:hover{
  background-color: #e83939;
}

.questionButton:focus{
  border: none;
  outline: none;
}

.radioInput{
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #a12525;
  outline: none;
  margin-top: 3px;
  position: absolute;
}

.radioInput:checked{
  background-color: #e83939;
}

.checkboxInput{
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 2px solid #a12525;
  outline: none;
  margin-top: 3px;
  position: absolute;
}

.checkboxInput:checked {
  background-color: #e83939;
}

.textInput{
  width: 100%;
  border: 2px solid #a12525;
  padding: 4px;
  padding-left: 10px;
}

.fileInput{
  cursor: pointer;
  width: 100%;
  border: 2px solid #a12525;
  padding: 4px;
  padding-left: 10px;
}

#fileInput{
  display: none;
}